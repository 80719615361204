import React from "react"
import SEO from "../components/SEO/SEO"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div>
      <h1>NIET GEVONDEN</h1>
      <p>Je bent op een pagina terecht gekomen die niet bestaat of tijdelijk onbeschikbaar is.</p>
    </div>
  </>
)

export default NotFoundPage
